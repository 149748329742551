<template>
  <error-loading v-if="error" />
  <b-row v-else>
    <b-col cols="12" class="p-1">
      <newBasic
        :tiktokData="data.report.basic"
        :metrics="data.report.metrics"
        :features="data.report.features"
      ></newBasic>
      <mainCharts
        :tiktokData="data.report"
        :showEmbedTiktok="showEmbedTiktok"
      />
    </b-col>
    <b-col cols="12" class="p-1" v-if="data.report.features.blogger_views_likes_chart">
      <content-tiktok :media="mains" :graph="data.report.features.blogger_views_likes_chart.data" :username="data.report.basic.username" :showEmbedTiktok="showEmbedTiktok"/>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
export default {
  components: {
    BRow,
    BCol,
    newBasic: () => import('@/views/pages/profile/tiktok/newBasic.vue'),
    mainCharts: () => import('@/views/pages/profile/tiktok/charts/mainCharts.vue'),
    ContentTiktok: () => import('./ContentTiktok.vue'),
    ErrorLoading: () => import('../ErrorLoading.vue')
  },
  props: {
    data: {
      Type: Object,
      required: false
    },
    error: {
      Type: Boolean,
      required: true
    },
    showEmbedTiktok: {
      Type: Boolean,
      required: true
    }
  },
  computed: {
    mains() {
      return this.data.report.features.blogger_views_likes_chart.data.filter(item => item.is_recommended === true)
    }
  }
}
</script>
